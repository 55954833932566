<template lang="pug">
  h1.text-center.mt-5: a(:href="pdf") Открыть презентацию
</template>

<script>
const pdf = require('@/assets/presentation.pdf');

export default {
  name: 'ThePresentation',
  data: () => ({
    pdf,
  }),
  created() {
    window.location.href = pdf;
  },
};
</script>
